import React, {Component} from 'react';
// import Slider from 'react-slick';

// import Breadcrumb from "../common/breadcrumb";
// import {Slider2, Team4} from "../../services/script"

import {Helmet} from 'react-helmet'
class aboutUs extends Component {

    constructor (props) {
        super (props)

    }

    render (){

  

        return (
            <div>
                {/* <Breadcrumb title={'About Us'}/> */}
                {/*about section*/}
                <Helmet>
                     <title>Cool and Cool - Shop Online About Us</title>
                    <meta name="description" content="Cool & Cool a leading international FMCG brand, is known for its exceptional reputation followed by delivering trustworthy and reliable premium quality products. Established in 2004 and marketed by ABC International Ltd., UAE, Cool & Cool offers a diverse selection of high-quality products, which are spread amongst different categories such as Mother & Baby Care, Skin Care, Personal Care, Convenient Wipes, Paper and Detergents." />
                </Helmet> 
                <section className="about-page  section-b-space">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-3">
                                <div className="banner-section">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/logos/logo.png`} className="img-fluid" alt=""/>
                                </div>
                            </div>
                            <div className="col-sm-12">
                             
                                <p style={{
    textAlign: 'center'}}>Cool & Cool a leading international FMCG brand, is known for its exceptional reputation followed by delivering trustworthy and reliable premium quality products. Established in 2004 and marketed by ABC International Ltd., UAE, Cool & Cool offers a diverse selection of high-quality products, which are spread amongst different categories such as Mother & Baby Care, Skin Care, Personal Care, Convenient Wipes, Paper and Detergents. With a market presence in over 25 countries across the globe, Cool & Cool has always stayed put to its commitment towards implementing innovative product development and impeccable customer service.</p>
                            </div>
                        </div>
                    </div>
                </section>

          
            </div>
        )
    }
}

export default aboutUs