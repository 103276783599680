import React, {Component} from 'react';
import { Link} from 'react-router-dom';
import {connect} from 'react-redux';
import {SlideUpDown} from "../../../services/script"
import LogoImage from "../headers/common/logo"

class FooterFour extends Component {
    
    constructor (props) {
        super (props)

    }
    componentDidMount(){
        var contentwidth = window.innerWidth;
        if ((contentwidth) < 750) {
            SlideUpDown('slide-up');
        } else {
            var elems = document.querySelectorAll(".footer-title");
            [].forEach.call(elems, function(elemt) {
                let el = elemt.nextElementSibling;
                el.style = "display: block";
            });
        }
    }


    render () {
        const{socialLinks,country} = this.props;
        return (
            <footer className="">
                <div className="white-layout">
                    <div className="container">
                        <section className="small-section">
                            <div className="row">
                       
                            </div>
                        </section>
                    </div>
                </div>
                <section className="section-b-space darken-layout">
                    <div className="container">
                        <div className="row footer-theme partition-f">
                            <div className="col-lg-3 col-md-6">
                                <div className="footer-title footer-mobile-title">
                                    <h4 className="footer-header" >about</h4>
                                </div>
                                <div className="footer-contant">
                                    <div className="footer-logo d-flex justify-content-center">
                                        <LogoImage logo={this.props.logoName} />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-6">
                                <div className="row">
                                    <div className="sub-title col-lg-12 col-md-6">
                                        <div className="footer-title my-md-2 Active">
                                            <h4 className="footer-header align-footer-headers"  style={{
                                                color: '#fff',
                                              
                                                fontSize: '12px',
                                                fontWeight:'700',
                                                margin:'0px'
                                            }}>Connect With us</h4>
                                        </div>
                                        <div className="footer-contant">
                                            <div className="contact-list">
                                                <div className="row align-footers" >
                                                    {socialLinks[country] ? 
                                                        socialLinks[country].map(e=>{
                                                            return  <><div >
                                                                        <a href={e.Link} target="_blank">
                                                                            <img className="img-fluid m-1 social-media" src={`https://portal.coolandcool.pk/${e.Icon}` }  alt=""/>
                                                                        </a>
                                                                    </div>
                                                                    </>
                                                        })
                                                    :
                                                    ''
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-2 ">
                                    <div className="sub-title col-lg-12 col-md-6">
                                        <div className="footer-title my-md-2 ">
                                            <h4 className="footer-header align-footer-headers"  style={{
                                                color: '#fff',
                                                fontSize: '12px',
                                                fontWeight:'700',
                                                margin:'0px',
                                            
                                            }}>Payment Methods</h4>
                                        </div>
                                        
                                        <div className="footer-contant">
                                        <div className="row align-footers">
                                             {(country==="UAE") ?
                                          <> <div>
                                           <img className=" img-fluid payment-media"  src="/assets/images/icon/visa.svg" alt=""/> 
                                            </div>
                                            <div>
                                            <img className=" img-fluid payment-media"  src="/assets/images/icon/cod.svg"alt=""/>
                                            </div>
                                            <div>
                                            <img className=" img-fluid payment-media"  src="/assets/images/icon/mc_vrt_rev.svg" width="56px" alt=""/> 
                                            </div></> : <>
                                            <div>
                                            <img className=" img-fluid payment-media"  src="/assets/images/icon/cod.svg"alt=""/>
                                            </div></> }
                                            
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className="col-lg-2 col-md-6">
                                <div className="row">
                                    <div className="sub-title col-lg-12 col-md-6">
                                        <div className="footer-title my-md-2 Active">
                                            <h4 className="footer-header align-footer-headers"  style={{
                                                color: '#fff',
                                          
                                                fontSize: '12px',
                                                fontWeight:'700',
                                                margin:'0px'
                                            }}>download our app</h4>
                                        </div>
                                        <div className="footer-contant">
                                            <div className="contact-list">
                                                <div className="row align-footers" >
                                                    <div>
                                                        <a href="https://play.google.com/store/apps/details?id=com.sg.coolapp">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/google-play.svg`} className="img-fluid p-2" alt=""/>
                                                        </a>
                                                    </div>
                                                    <div>
                                                        <a href="https://apps.apple.com/us/app/cool-cool/id1493928738">
                                                            <img src={`${process.env.PUBLIC_URL}/assets/images/appstore.svg`} className="img-fluid p-2" alt=""/>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>





                         
              
                            <div className="col-lg-2 col-md-6">
                                <div className="sub-title">
                                    <div className="footer-title my-md-1 slide-up">
                                        <h4 className="footer-header"  style={{
                                                color: '#fff',
                                              
                                                fontSize: '12px',
                                                fontWeight:'700',
                                                margin:'0px'
                                        }}>About Us</h4>
                                    </div>
                                    <div className="footer-contant">
                                        <ul className="contact-list" style={{marginTop:'10px'}}>
                                            <li className="footer-header" style={{ fontSize:'12px',display:'block',padding:'0px 0px'}}>
                                            <Link to={`/about-us`}>
                                               About Us
                                            </Link>
                                            </li>
                                            <li className="footer-header" style={{ fontSize:'12px',display:'block',padding:'0px 0px'}}>
                                            <Link to={`/privacy-policy`}>
                                              Privacy Policy
                                            </Link>
                                            </li>
                                       
                                            <li className="footer-header" style={{ fontSize:'12px',display:'block',padding:'0px 0px'}}>
                                            <Link to={`/terms-conditions`}>
                                                 Terms and Conditions
                                            </Link>
                                            </li>
                                      
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-6">
                                <div className="sub-title">
                                    <div className="footer-title my-md-1 slide-up">
                                        <h4 className="footer-header"  style={{
                                                color: '#fff',
                                               
                                                fontSize: '12px',
                                                fontWeight:'700',
                                                margin:'0px'
                                        }}>HELP</h4>
                                    </div>
                                    <div className="footer-contant">
                                    
                                        <ul className="contact-list">
                                       
                                                <li className="footer-header" style={{ fontSize:'12px',display:'block',padding:'0px 0px'}}>
                                                <Link to={`/delivery-terms`}>
                                                    Delivery Infromation
                                                </Link>
                                                </li>
                                         
                                            <li className="footer-header" style={{ fontSize:'12px',display:'block',padding:'0px 0px'}}>
                                            <Link to={`/faq`}>
                                            FAQ`s
                                            </Link>
                                            </li>
                                            <li className="footer-header" style={{ fontSize:'12px',display:'block',padding:'0px 0px'}}>
                                            <Link to={`/contact`}>
                                             Contact Us
                                             </Link>
                                            </li>
                                     
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <div className="sub-footer dark-subfooter footer-bar">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-md-6 col-sm-12">
                                <div className="footer-end">
                                    <p><i className="fa fa-copyright" aria-hidden="true"></i> Copyrighted by Cool and Cool. All Rights Reserved. </p>
                                </div>
                            </div>
                      
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}


const mapStateToProps = (state) => ({
    socialLinks: state.data.socialLinks,
    country: state.filters.country

})


export default connect(mapStateToProps)(FooterFour);