import React, { Component } from "react";
import NetflixLoader from "./ImageGrid.jsx";
import { Helmet } from "react-helmet";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import $ from "jquery";
import Pagination from "react-js-pagination";

import { getTotal, getCartProducts } from "../../../reducers";
import {
  addToCart,
  addToWishlist,
  addToCompare,
  changeCurrency,
  recieveSubcategory,
} from "../../../actions";
import { getVisibleproducts, getProducts, getSubcatProducts } from "../../../services";
import ProductListItem from "./product-list-item";
import { getTopCollection } from "../../../services";
import { subcategories } from "../../../constants/Endpoints.js";
import { TranslateConsumer } from "react-redux-multilingual/lib/context";

class ProductListing extends Component {
  constructor(props) {
    super(props);
    //console.log("poducts q", props);
    this.state = {
      limit: 20,
      hasMoreItems: true,
      activePage: 1,
      totalPages: this.props.products.length / 20,
    };
  }

  componentWillMount() {
    this.fetchMoreItems();
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: pageNumber });
    $(".product-wrapper-grid").hide();
    $(".Loader").fadeIn("slow", function() {
      var body = $("html, body");
      body
        .stop()
        .animate(
          { scrollTop: $(".collection-product-wrapper").offset().top - 20 },
          500,
          "swing",
          function() {
            $(".Loader").fadeOut("slow", function() {
              $(".product-wrapper-grid").fadeIn("slow", function() {
                // Animation complete
              });
            });
          }
        );
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.category !== this.props.category) {
      window.location.reload(false);
    }
  }

  fetchMoreItems = () => {
    if (this.state.limit >= this.props.products.length) {
      this.setState({ hasMoreItems: false });
      return;
    }
    // a fake async api call

    this.setState({
      limit: this.state.limit + 20,
    });
  };

  render() {
    const {
      products,
      addToCart,
      symbol,
      addToWishlist,
      addToCompare,
    } = this.props;
   // console.log(this.props.category, this.props.products)
    const MyListLoader = () => <NetflixLoader />;

    

    return (
      <div>
        {/*SEO Support*/}
        <Helmet>
          <title>
            Cool and Cool {`${localStorage.getItem("country")}`} || Shop Online{" "}
            {this.props.category}
          </title>
          <meta name="keywords" content={`${this.props.category}`} />
        </Helmet>
        {/*SEO Support End */}
        <div className="Loader" style={{ marginLeft: "5%", display: "none" }}>
          <MyListLoader />
        </div>
        <div className="product-wrapper-grid">
          <div className="container-fluid">
              
            {products[this.state.activePage - 1] !== undefined ? (
              <div className="row">
                {products[this.state.activePage - 1].map((product, index) => (
                  <div
                    className={`${
                      this.props.colSize === 3
                        ? "col-xl-3 col-md-6 col-grid-box cat-product"
                        : "col-lg-" + this.props.colSize
                        
                    }` }
                    
                    key={index}
                    
                  >
                    <ProductListItem
                      product={product}
                      symbol={symbol}
                      onAddToCompareClicked={() => addToCompare(product)}
                      onAddToWishlistClicked={() => addToWishlist(product)}
                      onAddToCartClicked={addToCart}
                      key={index}
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div className="row">
                <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                  <img
                    src={`${
                      process.env.PUBLIC_URL
                    }/assets/images/empty-search.jpg`}
                    className="img-fluid mb-4"
                  />
                  <h3>End Reached !</h3>

                  <Link
                    to={`${process.env.PUBLIC_URL}/`}
                    className="btn btn-solid"
                  >
                    continue shopping
                  </Link>
                </div>
              </div>
            )}

            <div>
              <Pagination
                activePage={this.state.activePage}
                itemsCountPerPage={this.state.totalPages}
                totalItemsCount={products.length}
                pageRangeDisplayed={this.state.totalPages + 1}
                onChange={this.handlePageChange.bind(this)}
                innerClass="pagination justify-content-center"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  products: ownProps.subcategory ? getSubcatProducts(state.data, state.filters, ownProps.search)
 : getProducts(state.data, state.filters, ownProps.search),
  topproducts: getTopCollection(state.data.products),
  symbol: state.data.symbol,
  country: state.data.country,
  subcategories: state.data.subcategories,
});

export default connect(
  mapStateToProps,
  { addToCart, addToWishlist, addToCompare, recieveSubcategory }
)(ProductListing);
