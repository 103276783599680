/**
 * Mocking client-server processing
 */
 import _products from './data.json'
 import * as url from '../constants/Endpoints'
 import axios from 'axios'
 import { useEffect } from 'react'
 
 import _sliderImages from './images.json'
 import { getPrivatePolicy } from '../actions'
 
 const TIMEOUT = 100
 
 export default {
     getProducts: (cb, timeout) => {
         getData(url.base_url+url.getAllProducts)
         .then( async res => {
             let data = [] ;
             await  res.data.map(d =>{
               
               var img = d.Image.split(",").map(e => {
                 return url.base_url+e;
               });
 
 
                 var obj = {}; 
                 obj.id = d.Id;
                 obj.name = d.Name;
                 obj.price = d.Price || d.UAEPrice;
                 obj.discount = d.CampaignId;
                 obj.features = d.Features;
                 obj.pictures = img;
                 obj.shortDetails = d.Feature;
                 obj.description = d.Description;
                 obj.stock = 1000;
                 obj.new = d.IsNew;
                 obj.isfeatured = d.IsFeatured;
                 obj.category = d.Category;
                 obj.subCategory= d.SubCategory
                 obj.country = d.Country;
                 obj.rating = 5;
                 obj.productCode = d.ProductCode;
                     data.push(obj);
             });
             cb(data)
             
           
         });
 
     
     },
     buyProducts: (payload, cb, timeout) => setTimeout(() => cb(), timeout || TIMEOUT),
     getMenu: (cb, timeout) => {
      
         getData(url.base_url+url.categories)
         .then(res => {
           cb(res.data);
           
         });    
     },
 
     
     getSubcategory: (cb, timeout) => {
       getData(url.base_url+url.subcategories)
       .then(res => {
         cb(res.data);
        // console.log("data",res.data)
       });
     },
 
     getSliderimages: (cb, timeout) => {
       getData(url.base_url+url.getBanner+"home_sliders")
       .then(res => {
         cb(res.data);
       });
     },
 
     getHomebanner:(cb,timeout) => {
         getData(url.base_url+url.getBanner+"home_banners_web")
         .then(res => {
           cb(res.data);
         });
     },
 
 
     getfaq:(cb,timeout) => {
         getData(url.base_url+url.getFAQ)
         .then(res => {
           cb(res.data);
         });
     },
 
     getOrigin:(cb,timeout)=>{
 
     },
      
 
     postCustsignup:(payload,cb,timeout)=>{
         postData(url.base_url+url.custSignUp,payload)
         .then(res => {
           cb(res.data);
         });
        
         
     },
     postCustsignIn:(payload,cb,timeout)=>{
         getDataParam(url.base_url+url.custSignIn,payload)
         .then(res => {
           cb(res.data);
           console.log("paramsdata", res.data)
         });
        
         
     },
 
     
 
     postPlaceOrder:(payload,cb,timeout)=>{
       postData(url.base_url+url.placeOrder,payload)
       .then(res => {
         cb(res.data);
       });
      
       
   },
 
 
   getDeals:(cb) =>{
     getData(url.base_url+url.getDeals)
     .then(async res => {
       let data = [] ;
 
       await  res.data.map(d =>{
       
 
         var img = d.Image.split(",").map(e =>{
    
           return url.base_url+e;
         });
 
 
           var obj = {}; 
           obj.id = d.Id;
           obj.name = d.Name;
           obj.price = d.Price || d.UAEPrice;
           obj.discount = d.Discount;
           obj.DOTD = d.DOTD;
           obj.pictures = img;
           obj.features = d.Features;
           obj.shortDetails = d.Feature;
           obj.description = d.Description;
           obj.stock = 100;
           obj.new = d.IsNew;
           obj.isfeatured = d.IsFeatured;
           obj.category = d.Category;
           obj.subCategory= d.SubCategory
           obj.country = d.Country;
           obj.rating = 5;
           obj.productCode = d.ProductCode;
               data.push(obj);
       });
       cb(data);
     });
   },
 
   getSettings:(cb) =>{
     getData(url.base_url+url.getSettings).then( async d =>{
       cb(d.data);
     });
   },
 
   getPrivatePolicy:(cb) =>{
     getData(url.base_url+url.getPrivatePolicy).then( async d =>{
       cb(d.data);
     });
   },
   getSocialLink:(cb) =>{
     getData(url.base_url+url.getSociallink).then( async d =>{
       cb(d.data);
     });
   },
   postContact:(payload,cb,timeout)=>{
     postData(url.base_url+url.placeContact,payload)
     .then(res => {
       cb(res.data);
     });
    
     
 },
 getOrderRef:(payload,cb,timeout)=>{
   getData(url.base_url+url.getOrderRef+payload)
   .then(res => {
     cb(res.data);
   });
  
   
 },
 
 checkcoupoun:(payload,cb,timeout)=>{
   getData(url.base_url+url.checkCoupon+"Name="+payload.coupoun+"&Country="+payload.country)
   .then(res => {
     cb(res.data);
   });
 },
 
 getContactDetail:(cb)=>{
   getData(url.base_url+url.getContactDetails)
     .then(res => {
       cb(res.data);
     });
 },
 
 getfetchSideDetails:(cb)=>{
   getData(url.base_url+url.sideDetails)
     .then(res => {
       cb(res.data);
     });
 },
 getDealsBanner: (cb, timeout) => {
   getData(url.base_url+url.getBanner+"deals")
   .then(res => {
     cb(res.data);
   });
 },
 
 getBestSeller: (cb, timeout) => {
   getData(url.base_url+url.getBestSeller)
   .then(res => {
     cb(res.data);
   });
 },
 
 
 postSocialLogin:(payload,cb,timeout)=>{
   postData(url.base_url+url.socialLogin,payload)
   .then(res => {
     cb(res.data);
   });
  
   
 },
 }
 
 
 function getData(URL){
    return axios.get(URL);
 }
 
 
 function postData(URL,data){
     return axios.post(URL,data);
  }
 
 function getDataParam(URL,data){
     return axios.get(URL, {
         params: data
       });
  }
  