import React, {Component} from 'react';
import Slider from 'react-slick';
import {connect} from 'react-redux';
import Breadcrumb from "../common/breadcrumb";
import {Slider2, Team4} from "../../services/script"

class webTerms extends Component {

    constructor (props) {
        super (props)

    }

    render (){

        const {webterms} = this.props;

        return (
            <div>
                {/* <Breadcrumb title={'About Us'}/> */}
                {/*about section*/}
                <section className="about-page  section-b-space">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-3">
                                <div className="banner-section">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/logos/logo.png`} className="img-fluid" alt=""/>
                                </div>
                            </div>
                            <div className="col-sm-12">
                            
                                <p style={{textAlign: 'center'}}>{webterms["Terms"]}</p>
                            </div>
                        </div>
                    </div>
                </section>

          
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    webterms: state.data.privatepolicy,

})


export default connect(mapStateToProps)(webTerms);