import React, {Component} from 'react';
import Breadcrumb from "../common/breadcrumb";
import {connect} from 'react-redux';
import {Helmet} from 'react-helmet'

class Faq extends Component {

    constructor (props) {
        super (props)
    }

    render (){

        const {faq,country} = this.props;
        return (
            <div>
                {/* <Breadcrumb title={'Faq'}/> */}
                
                    {/*SEO Support*/}
                    <Helmet>
                    <title>Cool and Cool {this.props.country} || Shop Online FAQ</title>
                    <meta name="keywords" content="Frequent Ask Questions" />
                    <meta name="description" content="Frequent Ask Questions" />
                    </Helmet>
                    {/*SEO Support End */}
                {/*Dashboard section*/}
                <section className="faq-section section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="accordion theme-accordion" id="accordionExample">
                                    
                                { faq.filter(e =>{
                                    return e.Country === country
                                }).map((e, index ) =>
                                        <div className="card" key={index}>
                                        <div className="card-header" id="headingOne">
                                            <h5 className="mb-0">
                                                <button className="btn btn-link" type="button" data-toggle="collapse"
                                                        data-target="#collapseOne" aria-expanded="true"
                                                        aria-controls="collapseOne">
                                                    {e.Question}
                                                </button>
                                            </h5>
                                        </div>

                                        <div id="collapseOne" className="collapse show" aria-labelledby="headingOne"
                                             data-parent="#accordionExample">
                                            <div className="card-body">
                                            <p> {e.Answer}</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                
                           </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    faq: state.data.faq,
    country:state.filters.country

})

export default connect(mapStateToProps)(Faq);
