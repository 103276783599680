import React, {Component, memo} from 'react';
import NewProduct from "../common/new-product";
import Filter from "./common/filter";
import FilterBar from "./common/filter-bar";
import ProductListing from "./common/product-listing";
import StickyBox from "react-sticky-box";
import { connect } from 'react-redux';
import { getTopCollection, getCategory, getSubCategory } from "../../services";
import { filterSubcat } from "../../actions";
import { Helmet } from "react-helmet";



class SubCollectionRightSidebar extends Component {

    state = {
        layoutColumns:3
    }
    componentDidMount() {
        var Subcategories = [];
        Subcategories.push(this.props.match.params.id);
        this.props.filterSubcat(Subcategories);
        document.getElementById("color").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/color18.css` );
    }

    LayoutViewClicked(colums) {
        this.setState({
            layoutColumns:colums
        });
    }
    openFilter = () => {
        document.querySelector(".collection-filter").style = "left: -15px";
      };
     
      render() {
        const { subcatDetails, subCategory } = this.props;
        console.log("sub",subCategory)
        return (
          
          <div>
            {/* <Breadcrumb title={this.props.category}/> */}
            <Helmet>
              {/* <title>Cool and Cool {`${this.props.country}`} || Shop Online {this.props.category}</title> */}
              <meta name="description" content={subCategory} />
            </Helmet>
            <section
              className="section-b-space section-b-space j-box pets-box ratio_square "
              style={{ paddingTop: "40px" }}
            >
              <div className="collection-wrapper">
                <div className="container">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="top-banner-wrapper">
                        <div className="top-banner-content small-section">
                          <div className="title1 title5">
                            <h1
                              className="title-inner1"
                              style={{ letterSpacing: "5px", fontSize: "20px" }}
                            >
                              {subCategory}
                            </h1>
                            <hr role="tournament6" />
                          </div>
                          {/* <div class="row align-items-center">
                                                                    
                                                                </div>
                                                               */}
                          <div className="row justify-content-center">
                            <div className="col-8">
                              {/* <p className="text-center">
                                {catDetails[0].Description}
                              </p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="collection-content col">
                      <div className="page-main-content">
                        <div className="">
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="collection-product-wrapper">
                                <div className="product-top-filter">
                                  <div className="container-fluid p-0">
                                    <div className="row">
                                      <div className="col-xl-12">
                                        <div className="filter-main-btn">
                                          <span
                                            onClick={this.openFilter}
                                            className="filter-btn btn btn-theme"
                                          >
                                            <i
                                              className="fa fa-filter"
                                              aria-hidden="true"
                                            />{" "}
                                            Filter
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-12">
                                        <FilterBar
                                        subcategory ={true}
                                          onLayoutViewClicked={(colmuns) =>
                                            this.LayoutViewClicked(colmuns)
                                            
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
    
                                <ProductListing
                                  category={this.props.subCategory}
                                  colSize={this.state.layoutColumns}
                                  subcategory={true}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-3 collection-filter">
                      <StickyBox offsetTop={20} offsetBottom={20}>
                        <div>
                          <Filter {...this} />
                          <NewProduct />
                        </div>
                      </StickyBox>
                      {/*side-bar banner end here*/}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        );
      }

}



const mapStateToProps = (state, ownProps) => {
    return {
    //  category:ownProps.props.category,
      subCategory:ownProps.match.params.id,
      topproducts: getTopCollection(state.data.products),
      symbol: state.data.symbol,
      subcatDetails: getSubCategory(state.data.menu, ownProps.match.params.id),
    };
  };
  
  export default connect(
    mapStateToProps,
    { filterSubcat }
  )(SubCollectionRightSidebar);