import React, { Component } from 'react';
import store from '../../store';
import { clearCart } from '../../actions'
import { SendContact } from '../../actions'
import { connect } from 'react-redux'
import { getCartTotal, getAppliedVAT, getVatTotal, getTotalDiscount, getItemDiscount } from "../../services";
class orderSuccess extends Component {

    constructor(props) {
        super(props)

    }
    componentDidMount() {
        store.dispatch(clearCart([]));
    }

    render() {

        const { payment, items, symbol, orderTotal, vat, subtotal, couponDiscount, vatAmount, totAmount } = this.props.location.state;
        var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        var current = new Date();
        let CheckDate = current.toLocaleDateString("en-US", options).toString()
        var next5days = new Date(this.props.orderStatusRef.DeliveryDate);
        let deliveryDate = next5days.toLocaleDateString("en-US", options).toString()
        const { country } = this.props;
        return (
            (payment) ?
                <div>
                    <section className="section-b-space light-layout">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="success-text">
                                        <i className="fa fa-check-circle" aria-hidden="true"></i>
                                        <h2>Thank you</h2>
                                        <p>Your Order has been Placed Successfully</p>
                                        <p>Transaction ID: {payment}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="section-b-space">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="product-order">
                                        <h3>your order details</h3>
                                        {items.map((item, index) => {
                                            return <div className="row product-order-detail" key={index}>
                                                <div className="col">
                                                    <img src={item.variants ?
                                                        item.variants[0].images
                                                        : item.pictures[0]} alt="" className="" />
                                                </div>
                                                <div className="col order_detail">
                                                    <div>
                                                        <h4>product name</h4>
                                                        <h5>{item.name}</h5>
                                                    </div>
                                                </div>
                                                <div className="col order_detail">
                                                    <div>
                                                        <h4>quantity</h4>
                                                        <h5>{item.qty}</h5>
                                                    </div>
                                                </div>
                                                <div className="col order_detail">
                                                    <div>
                                                        <h4>Discount</h4>
                                                        <h5>{symbol} {item.applieddiscount}</h5>
                                                    </div>
                                                </div>
                                                <div className="col order_detail">
                                                    <div>
                                                        <h4>price</h4>
                                                        <h5>{symbol}{item.sum}</h5>
                                                    </div>
                                                </div>
                                            </div>




                                        })}
                                        <div className="total-sec">
                                            <ul>
                                                <li>subtotal <span>{symbol}{subtotal}</span></li>
                                                {
                                                    (subtotal > this.props.settings[country][0].Limit) ? '' :
                                                        <li >{this.props.settings[country][0].Name} <span>{symbol}{this.props.settings[country][0].Value}</span></li>
                                                }
                                                {
                                                    (couponDiscount == 0) ? '' :
                                                        <li>Coupon Discount <span>{symbol}{couponDiscount}</span></li>
                                                }
                                                <li>{this.props.settings[country][1].Name} <span>{symbol}{vatAmount}</span></li>
                                            </ul>
                                        </div>
                                        <div className="final-total">
                                            <h3>total <span>{symbol}{totAmount}</span></h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="row order-success-sec">
                                        <div className="col-sm-6">
                                            <h4>Order Summary</h4>
                                            <ul className="order-detail">

                                                <li>Order ID: {payment}</li>
                                                <li>Order Date: {CheckDate}</li>
                                                <li>Order Total: {symbol}{totAmount}</li>
                                            </ul>
                                        </div>

                                        {/* <div className="col-sm-12 payment-mode">
                                        <h4>payment method</h4>
                                        <p>Pay on Delivery (Cash/Card). Cash on delivery (COD) available. Card/Net
                                            banking acceptance subject to device availability.</p>
                                    </div> */}
                                        <div className="col-md-12">
                                            <div className="delivery-sec">
                                                <h3>expected date of delivery</h3>
                                                {
                                              ( localStorage.getItem('country') === 'UAE')
                                                ? <p>Your order will be delivered to you within 2 business days.</p>
                                                : <p>Your order will be delivered to you within 3 - 4 business days.</p>
                                             }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                </div>
                :
                <section className="p-0">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="error-section">
                                    <h1>{payment.Message}</h1>
                                    <h2>page not found</h2>
                                    <a href="index.html" className="btn btn-solid">back to home</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
        )
    }
}
const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    symbol: state.data.symbol,
    total: getCartTotal(state.cartList.cart),
    user: state.user.user,
    country: state.filters.country,
    orderRef: state.user.orderRef,
    settings: state.data.settings,
    vatincluded: getAppliedVAT(state.data.settings[state.filters.country][1].Value, getCartTotal(state.cartList.cart), state.data.settings[state.filters.country][0].Value),
    totalDiscount: getVatTotal(state.data.settings[state.filters.country][1].Value, state.cartList.cart),
    orderStatusRef: state.user.orderStatus
})

export default connect(
    mapStateToProps
)(orderSuccess);