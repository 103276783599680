import React, {Component} from 'react';
import Breadcrumb from "../common/breadcrumb";
import NewProduct from "../common/new-product";
import Filter from "./common/filter";
import FilterBar from "./common/filter-bar";
import ProductListing from "./common/product-listing";
import StickyBox from "react-sticky-box";
import { connect } from 'react-redux';
import {getTopCollection,getCategory} from '../../services';
import {filterSearch} from '../../actions'


class Search extends Component {

    constructor (props) {
        super (props)
     
    }

    state = {
        layoutColumns:3
    }
    componentDidMount() {
        document.getElementById("color").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/color18.css` );
        this.props.filterSearch(this.props.location.state.searchTerm);
        
    }

    LayoutViewClicked(colums) {
        this.setState({
            layoutColumns:colums
        })
    }

    openFilter = () => {
        document.querySelector(".collection-filter").style = "left: -15px";
    }

    componentDidUpdate(prevprops,prevstate){

        if( prevprops.searchTerm !== this.props.searchTerm){
            this.props.filterSearch(this.props.searchTerm);
            window.location.reload();
        }

           
       }
     
    componentWillUnmount(){
        this.props.filterSearch('');
    }
    
    render (){
        const {searchTerm} = this.props.location.state;
        // alert(searchTerm);
   
        return (
            <div style={{
                border: '1px #dddddd70 solid',  
                marginTop: '-1%'
            }}>
{/* 
                <Breadcrumb title={'Collection'}/> */}

                <section className="section-b-space" style={{paddingTop:'40px'}}>
                    <div className="collection-wrapper">
                        <div className="container">
                            <div className="row">
                            <div className="col-sm-12">
                            <div className="top-banner-wrapper">
                                                     
                                                     <div className="top-banner-content small-section">
                                                         <div className="title1 title5">
                                                             <h2 className="title-inner1" style={{letterSpacing:'5px', fontSize:'25px'}}>SEARCH</h2>
                                                             <hr role="tournament6" />
                                                             
                                                         </div>
                                                   
                                                     </div>
                                                 </div>
                                                </div>
                                <div className="collection-content col">
                                    <div className="page-main-content">
                                        <div className="">
                                            <div className="row">
                                           
                                                <div className="col-sm-12">
                                                  
                                                    <div className="collection-product-wrapper">
                                                        <div className="product-top-filter">
                                                            <div className="container-fluid p-0">
                                                                <div className="row">
                                                                    <div className="col-xl-12">
                                                                        <div className="filter-main-btn">
                                                                            <span onClick={this.openFilter}
                                                                                className="filter-btn btn btn-theme"><i
                                                                                className="fa fa-filter"
                                                                                aria-hidden="true"></i> Filter</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-12">
                                                                        <FilterBar onLayoutViewClicked={(colmuns) => this.LayoutViewClicked(colmuns)}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/*Products Listing Component*/}
                                                        <ProductListing colSize={this.state.layoutColumns} search={true}/>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3 collection-filter">

                                    <StickyBox offsetTop={20} offsetBottom={20}>
                                        <div>
                                            <Filter {...this}/>
                                            <NewProduct/>
                                        
                                        </div>
                                    </StickyBox>
                                    {/*side-bar banner end here*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {

    return {
    category:ownProps.match.params.id,
    topproducts: getTopCollection(state.data.products),
    symbol: state.data.symbol,
    catDetails:getCategory(state.data.menu,ownProps.match.params.id),
    search:state.filters.search,
    searchTerm:ownProps.location.state.searchTerm,
}
}

export default connect(
    mapStateToProps,{filterSearch})(Search)

