import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslate } from 'react-redux-multilingual'
import * as url from '../../../../constants/Endpoints'
import {connect} from 'react-redux';
//import SubMenu from './submenu';
class NavBarMob extends Component {
    constructor(props) {
        super(props);

        this.state = {
            navClose: { right: '0px',display: 'flex',width:window.innerWidth},
            backnav:{margin:'0px auto',display:'none'},
            navMenuisClosed: true,
            width:window.innerWidth,
            subCategoryToggle: false,
            activeCategory: 'none',
            activeSubcat : 'none',
            
        }
  
    }
   

    componentWillMount() {
        window.addEventListener('resize', this.handleResize)
        if (window.innerWidth < 750) {
            this.setState({ navClose: { right: '-410px',display: 'none',width:window.innerWidth},backnav:{display:'block',margin:'0px auto'} })
        }
        if (window.innerWidth < 1199) {
            this.setState({ navClose: { right: '-300px',display: 'none',width:window.innerWidth},backnav:{display:'block',margin:'0px auto'} })
        }
    }

    openNav() {
    if(this.state.navMenuisClosed){
        this.setState({  navClose: { right: '0px',display: 'flex',width:window.innerWidth},backnav:{display:'block',margin:'0px auto'},navMenuisClosed:false})
    }else{
        this.setState({  navClose: { right: '0px',display: 'none',width:window.innerWidth},backnav:{display:'none',margin:'0px auto'},navMenuisClosed:true })
    }
       
    }
    closeNav() {
        this.setState({  navClose: { right: '0px',display: 'none',width:window.innerWidth},backnav:{display:'none',margin:'0px auto'},navMenuisClosed:true  })
    }
    handleResize = () =>{
        this.setState({  width: window.innerWidth});
   
    }
    onMouseEnterHandler() {
        if (window.innerWidth > 1199) {
            document.querySelector("#main-menu").classList.add("hover-unset");
        }
    }

    handleSubmenu = (event) => {
        if (event.target.classList.contains('sub-arrow'))
            return;

        if(event.target.nextElementSibling.classList.contains('opensubmenu'))
            event.target.nextElementSibling.classList.remove('opensubmenu')
        else{
            document.querySelectorAll('.nav-submenu').forEach(function (value) {
                value.classList.remove('opensubmenu');
            });
            document.querySelector('.mega-menu-container').classList.remove('opensubmenu')
            event.target.nextElementSibling.classList.add('opensubmenu')
        }
    }

    handleMegaSubmenu = (event) => {
        if (event.target.classList.contains('sub-arrow'))
            return;
            
        if(event.target.parentNode.nextElementSibling.classList.contains('opensubmegamenu'))
            event.target.parentNode.nextElementSibling.classList.remove('opensubmegamenu')
        else{
            document.querySelectorAll('.menu-content').forEach(function (value) {
                value.classList.remove('opensubmegamenu');
            });
            event.target.parentNode.nextElementSibling.classList.add('opensubmegamenu')
        }
    }

    render() {
        const { translate,items,user,subcategoryData} = this.props;
        const handleOnMouseOver = (e) => {
            const {name} = e.target
            this.setState({
                subCategoryToggle: true,
                activeCategory: name,
                

            })
        }
        const MouseOver = (e) => {
            const {name} = e.target
            this.setState({
                subCategoryToggle: true,
                activeSubcat: name,
                

            })
        }
        const handleOnMouseLeave = () => {
            this.setState({
                subCategoryToggle: false,
                activeCategory:'none'
            })
        }

        return (
            <div>
                <div className="main-navbar">
                    <div id="mainnav" >
                        <div className="toggle-nav" onClick={this.openNav.bind(this)} >
                            <i className="fa fa-bars sidebar-bar"></i>
                        </div>
                        <ul className="nav-menu justify-content-center" style={this.state.navClose}>
                       <Link to="/" className="general-menu"> <li >
                          HOME
                        </li>
                        </Link>
                        <Link to="/about-us" className="general-menu" >  <li>
                          ABOUT US
                        </li>
                        </Link>
                        {(Object.keys(user).length > 0)?    
                            <Link to="/dashboard" className="general-menu" > <li>
                            MY ACCOUNT
                            </li>
                            </Link>
                        :
                            <Link to="/login" className="general-menu" > <li>
                            LOGIN
                            </li>
                            </Link>
                        }
                     
                        <Link to="/Deals-select" className="general-menu"  > 
                        <li>
                         DEALS
                         {/* <ul class="submenu">
                         <div class="arrow-up"></div>
                                <div className=" row subdeal">
                                    <li className="general-menu "><Link  className="general-menu " to={`/deals`} >DEALS AND OFFERS</Link></li>
                                    <li  className="general-menu "><Link  className="general-menu " to={`deals/dotd`} >DEAL OF THE DAY</Link></li>
                                </div>
                        </ul> */}
                        </li>
                        </Link>
                        <Link to="/faq" className="general-menu" > <li>
                            FAQ
                        </li>
                        </Link>
                        <Link to="/contact" className="general-menu" > <li>
                            CONTACT
                        </li>
                        </Link>
                        </ul>
                        <ul className="nav-menu  hi" id="nav-bar-scroll" style={this.state.navClose}>
               

                            {
                               items.map((menu, index ) =>
                                <li className='dropdown' key={index} style={{width:'85px', textAlign:'center'}}>
                                 
                                 <Link to={`/Category/${menu.Name}`} className="nav-link" 
                                 name={`${menu.Name}`}
                                 onMouseEnter={handleOnMouseOver}  
                                // onMouseLeave={handleOnMouseLeave}
                                style={{backgroundColor: this.state.activeCategory == menu.Name ? "#ADD8E6" : "transparent" }}
                                 >
                                        <img alt="" src={`${url.base_url}${menu.Icon}`} className="img-fluid" style={{
                                                                                     
                                                                                        width: '30px',
                                                                                        height: '30px',
                                                                                        margin: '0 auto',
                                                                                        display:'block',
                                                                                  

                                        }}/>
                                       
                                      <p style={{    
                                                    fontSize:'10px',
                                                    fontWeight: '600',
                                                  }}>{menu.Name
                                                    
                                                }</p> 
                               </Link>
                              
                              { this.props.country == "UAE" && menu.HasSubCategory && 
                              <ul className="dropdown-menu" style={{border : "none"}} >  
                                {  this.state.subCategoryToggle && subcategoryData.filter((item) =>
                                item.Category.includes(this.state.activeCategory))
                                    .map((item, index) => (
                                        <Link to={{pathname:`/SubCategory/${item.Name}`,
                                        state:{categ:`${menu.Name}`,subCat:`${item.Name}`}}}
                                        name={`${item.Name}`}
                                        onMouseEnter={MouseOver}
                                        style={{textTransform: this.state.activeSubcat == item.Name ? 
                                            "uppercase" : "capitalize" }}
                                        >
                                        
                                        <li className='col'   key={index}  >
    
                                            <p style={{  
                                                margin: "0 auto",  
                                                fontSize:'9px',
                                                fontWeight: '600',
                                                lineHeight: '1' }}>
                                            {item.Name}</p>

                                            
                                        </li>
                                        </Link>
                                        

                                    ))}
                                        
                        </ul>}
                                   
                                    
                                </li>
                               )
                            }
                        
                         
                            
                        </ul>
                    </div>
                    { /*<div className="row"  >
                        {  this.state.subCategoryToggle && this.state.subcategoryData.filter((item) => item.Category.includes(this.state.activeCategory))
                                        .map((item, index) => (
                                            <SubMenu  
                                                key={index} 
                                                image={`${url.base_url}${item.Image}`} 
                                                name={item.Name}
                                              > </SubMenu>
                                        ))}
                        
                                        </div> */}

                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    items: state.data.menu,
    user: state.user.user,
    subcategoryData: state.data.subcategories,
    country: state.filters.country,

    
})

export default connect(mapStateToProps)(withTranslate(NavBarMob));