
//base URL
export const base_url = 'https://portal.coolandcool.pk/'


export const categories = 'api/service/Categories'
export const getBanner = 'api/service/banners?pagename='
export const getAllProducts = "api/service/GetProducts"  
export const subcategories = 'api/service/SubCategories'  
export const getFAQ = "api/service/FAQs"
export const custSignUp = "api/service/Signup"
export const custSignIn = "api/service/login"
export const placeOrder  = "api/service/PlaceOrder"  
export const getDeals = "api/service/GetAllDeals"          
export const getSettings = 'api/service/SettingByCountry'
export const getPrivatePolicy = 'api/service/privacypolicyBycountry'
export const getSociallink = 'api/service/SocialLinks'
export const placeContact  = "api/service/ContactUs"
export const getOrderRef = 'api/service/GetOrderbyRef?OrderId='
export const checkCoupon  = '/api/service/Coupon?';
export const getContactDetails = 'api/service/contactdetails';
export const sideDetails = 'api/service/sidedetails';
export const getBestSeller = 'api/service/GetAllProductsByType?type=bestseller_web';
export const  socialLogin = '/api/service/SocialLogin';
